<template>
    <div class="py-6 lg:py-24">
        <div class="">
            <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <h2 class="text-2xl lg:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Let Greetmate do Cold Calls or Appointment Confirmation Calls.
                </h2>
                <dl class="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
                    <div v-for="feature in features" :key="feature.name">
                        <dt class="text-base font-semibold leading-7 text-gray-900">
                            <div class="pb-4">
                                <RoundedIcon :icon="feature.icon"/>
                            </div>

                            {{ feature.name }}
                        </dt>
                        <dd class="mt-1 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script setup>
import {mdiPhoneOutgoing, mdiCalendarAccountOutline} from "@mdi/js";

import RoundedIcon from "@/Components/Marketing/RoundedIcon.vue";

const features = [
    {
        name: 'Run Outbound Call Campaigns',
        description:
            'Create Contact lists and automate your cold calls, customer feedback surveys and more.',
        icon: mdiPhoneOutgoing,
    },
    {
        name: 'Auto-Confirm Appointments.',
        description:
            'Let Greetmate call your customers to confirm appointments, and send you a notification.',
        icon: mdiCalendarAccountOutline,
    },
]
</script>
